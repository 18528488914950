@import 'common/variables.scss';
@import 'common/mixins.scss';

.burger-menu {
  display: contents;
}

.burger-menu_active,
.burger-menu_button {
  cursor: pointer;
  display: none;
}

.menu_list {
  .menu_list_logo {
    margin-top: 104px;
    margin-bottom: 24px;
  }
}


.burger-menu_button {
  position: absolute;
  top: 48px;
  right: 34px;
  z-index: 30;
  height: 84px;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.burger-menu_lines::before,
.burger-menu_lines::after,
.burger-menu_lines {
  position: absolute;
  width: 25px;
  height: 3px;
  border-radius: 8px;
  background-color: $primary;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.burger-menu_lines {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.burger-menu_lines::before {
  content: '';
  top: -8px;
  border-radius: 8px;
}

.burger-menu_lines::after {
  content: '';
  top: 8px;
  border-radius: 8px;
}

.burger-menu_active .burger-menu_lines {
  background-color: transparent;
}

.burger-menu_active .burger-menu_lines::before {
  top: 0;
  transform: rotate(45deg);
}

.burger-menu_active .burger-menu_lines::after {
  top: 0;
  transform: rotate(-45deg);
}

.burger-menu_nav {
  width: 320px;
  padding: 32px 24px 24px;
  position: fixed;
  top: 0;
  z-index: 20;
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  height: 100%;
  background-color: $bg;
  overflow-y: auto;
  right: -100%;
  -webkit-transition: 0.8s;
  -moz-transition: 0.8s;
  -o-transition: 0.8s;
  transition: 0.8s;
}

.burger-menu_active .burger-menu_nav {
  right: 0;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.burger-menu_active {
  .burger-menu_button {
    background-color: transparent;
  }

  .burger-menu_lines:before,
  :after {
    background-color: $primary;
  }
}

.burger-menu_link {
  line-height: 20px;
  color: $text-color;
  text-decoration: none;
  font-weight: $medium-font-weight;
  position: relative;

  &:hover {
    color: $primary;

    .chevron-down-icon {
      filter: invert(51%) sepia(5%) saturate(4000%) hue-rotate(325deg) brightness(195%) contrast(98%);
    }
  }
}

.active {
  color: $primary;

  .chevron-down-icon {
    filter: invert(51%) sepia(5%) saturate(4000%) hue-rotate(325deg) brightness(195%) contrast(98%);
  }
}

._select {
  width: 100%;

  .dropdown-button {
    @include flex-row(space-between, center)
  }

  .dropdown-content {
    position: initial;

    a {
      text-decoration: none;
      color: $text-color;
    }
  }
}

.burger-menu_overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
}

.burger-menu_active .burger-menu_overlay {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}

.burger_navigation_list {

  li {
    padding: 16px 0;
  }
}

.menu-buttons {
  .select {
    img:last-of-type {
      margin-left: auto;
    }
  }
}

@media (max-width: 1199.98px) {
  .burger-menu_active,
  .burger-menu_button {
    display: initial;
  }
}

@media (max-width: 575.98px) {
  .burger-menu_button {
    top: 23px;
    right: 54px;
  }
}