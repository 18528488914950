.burger-menu {
  display: contents;
}

.burger-menu_active, .burger-menu_button {
  cursor: pointer;
  display: none;
}

.menu_list .menu_list_logo {
  margin-top: 104px;
  margin-bottom: 24px;
}

.burger-menu_button {
  z-index: 30;
  height: 84px;
  -o-transition: .4s;
  transition: all .4s;
  position: absolute;
  top: 48px;
  right: 34px;
}

.burger-menu_lines:before, .burger-menu_lines:after, .burger-menu_lines {
  width: 25px;
  height: 3px;
  -o-transition: .4s;
  background-color: #fcd3af;
  border-radius: 8px;
  transition: all .4s;
  position: absolute;
}

.burger-menu_lines {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.burger-menu_lines:before {
  content: "";
  border-radius: 8px;
  top: -8px;
}

.burger-menu_lines:after {
  content: "";
  border-radius: 8px;
  top: 8px;
}

.burger-menu_active .burger-menu_lines {
  background-color: #0000;
}

.burger-menu_active .burger-menu_lines:before {
  top: 0;
  transform: rotate(45deg);
}

.burger-menu_active .burger-menu_lines:after {
  top: 0;
  transform: rotate(-45deg);
}

.burger-menu_nav {
  width: 320px;
  z-index: 20;
  height: 100%;
  -o-transition: .8s;
  background-color: #1d1f24;
  flex-flow: column;
  justify-content: space-between;
  padding: 32px 24px 24px;
  transition: all .8s;
  display: flex;
  position: fixed;
  top: 0;
  right: -100%;
  overflow-y: auto;
}

.burger-menu_active .burger-menu_nav {
  -o-transition: .4s;
  transition: all .4s;
  right: 0;
}

.burger-menu_active .burger-menu_button {
  background-color: #0000;
}

.burger-menu_active .burger-menu_lines:before, .burger-menu_active :after {
  background-color: #fcd3af;
}

.burger-menu_link {
  color: #fff;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  position: relative;
}

.burger-menu_link:hover {
  color: #fcd3af;
}

.burger-menu_link:hover .chevron-down-icon {
  filter: invert(51%) sepia(5%) saturate(4000%) hue-rotate(325deg) brightness(195%) contrast(98%);
}

.active {
  color: #fcd3af;
}

.active .chevron-down-icon {
  filter: invert(51%) sepia(5%) saturate(4000%) hue-rotate(325deg) brightness(195%) contrast(98%);
}

._select {
  width: 100%;
}

._select .dropdown-button {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

._select .dropdown-content {
  position: initial;
}

._select .dropdown-content a {
  color: #fff;
  text-decoration: none;
}

.burger-menu_overlay {
  width: 100vw;
  height: 100vh;
  z-index: 10;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.burger-menu_active .burger-menu_overlay {
  background-color: #00000080;
  display: block;
}

.burger_navigation_list li {
  padding: 16px 0;
}

.menu-buttons .select img:last-of-type {
  margin-left: auto;
}

@media (max-width: 1199.98px) {
  .burger-menu_active, .burger-menu_button {
    display: initial;
  }
}

@media (max-width: 575.98px) {
  .burger-menu_button {
    top: 23px;
    right: 54px;
  }
}

/*# sourceMappingURL=index.7c20ca0a.css.map */
